import React from 'react'
import { Box, Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        gap: 1,
        width: 'fit-content',
        background: theme.palette.text.signalPills,
        // border: `1px solid ${theme.palette.text.resizer}`,
        borderRadius: '4px',
        position: 'relative',
        marginTop: '10px',
        marginLeft: '-10px',
    },
    button: {
        textTransform: 'none',
        border: 'none', // Remove the border for all buttons
        borderRadius: 'none',
        '&.MuiButton-outlined': {
            border: 'none', // Ensures the outlined variant has no border
        },
        '&.MuiButton-contained': {
            background: theme.palette.text.pillButton,
            color: theme.palette.text.buttonText,
        },
        padding: '3px 8px',
    },
    divider: {
        width: '1px',
        marginTop: '7px',
        backgroundColor: theme.palette.text.resizer,
        height: '20px',
        borderRadius: '3px',
        '&:hover': {
            color: `${theme.palette.text.violet}`,
        },
    },
}))

export const CustomTitle = ({ onTabClick, activeTabs }) => {
    const classes = useStyles()

    return (
        <Box display="flex" flexDirection="column" gap={2} paddingBottom={2}>
            <Box className={classes.buttonContainer}>
                {/* Investigating Pill */}
                <Button
                    variant={activeTabs.escalated ? 'contained' : ''}
                    onClick={() => onTabClick('escalated')}
                    className={classes.button}
                >
                    Investigating
                </Button>
                {/* <div className={classes.divider}></div> */}

                {/* Escalated to Customer Pill */}
                <Button
                    variant={activeTabs.outcome ? 'contained' : ''}
                    onClick={() => onTabClick('outcome')}
                    className={classes.button}
                >
                    Escalated to Customer
                </Button>
                {/* <div className={classes.divider}></div> */}

                {/* Closed Pill */}
                <Button
                    variant={activeTabs.closed ? 'contained' : ''}
                    onClick={() => onTabClick('closed')}
                    className={classes.button}
                >
                    Closed
                </Button>
            </Box>
        </Box>
    )
}
