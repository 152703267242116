import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import useSettings from 'src/hooks/useSettings';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Fallback from 'src/components/Fallback';
import { createMuiTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { ToastProvider } from './context/ToastContext';
import { CookiesProvider } from 'react-cookie';
import { Amplify } from 'aws-amplify';
import { AuthProvider, AuthContext } from 'src/context/JWTAuthContext';
import { SettingsProvider } from 'src/context/SettingsContext';
import Loader from './components/Loader';

const hostBool = window.location.hostname === 'localhost' ? false : true;
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'aws.cognito.signin.user.admin',
        'email',
        'openid',
        'phone',
        'profile',
      ],
      redirectSignIn: !hostBool
        ? 'http://localhost:3000'
        : process.env.REACT_APP_URL,
      redirectSignOut: !hostBool
        ? 'http://localhost:3000'
        : process.env.REACT_APP_URL,
      responseType: 'code',
    },
    mandatorySignIn: true,
  },
});


if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate:
      process.env.REACT_APP_ENVIRONMENT === 'production' ? 0.1 : 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

const App = () => {
  const currentPath = window.location.pathname;
  const docusaurusPath = currentPath.includes('/docs');

  useEffect(() => {
    if (docusaurusPath) {
      handleDocusaurusRedirect();
    }
  }, [docusaurusPath]);

  const handleDocusaurusRedirect = () => {
    const normalizedPath = currentPath.endsWith('/')
      ? currentPath.slice(0, -1)
      : currentPath;
    const pathWithoutIndex = normalizedPath.replace(/\/index\.html$/, '');
    const targetPath = `${pathWithoutIndex}/index.html`;

    if (currentPath.startsWith('/docs') && !currentPath.endsWith('/index.html')) {
      window.location.replace(targetPath);
    }
  };

  // Only render the rest of the app if not on a Docusaurus path.
  if (docusaurusPath) {
    return null; // Redirect handled in useEffect
  }

  return (
    <Sentry.ErrorBoundary fallback={() => <Fallback />} showDialog>
      <SettingsProvider>
        <AuthProvider>
          <ThemeWrapper />
        </AuthProvider>
      </SettingsProvider>
    </Sentry.ErrorBoundary>
  );
};

const ThemeWrapper = () => {
  const { settings } = useSettings();
  const { authToken } = useContext(AuthContext);

  const theme = createMuiTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {authToken ? (
            <Router>
              <CookiesProvider>{renderRoutes(routes)}</CookiesProvider>
            </Router>
          ) : (
            <Loader />
          )}
        </MuiPickersUtilsProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
