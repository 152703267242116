import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { ATTACK_SURFACES } from 'src/constants'
import {
    Box,
    Link,
    makeStyles,
    IconButton,
    Tooltip,
    Typography,
    Zoom,
} from '@material-ui/core'
import MUIDataTable, { debounceSearchRender } from 'mui-datatables'

import {
    exportFindings,
    getAllEngagements,
    getAllFindings,
    deleteFindings,
} from 'src/actions/openapi'
import {
    getColumnFilters,
    getSortValue,
    renameKeys,
    urlFilter,
} from 'src/utils/tableUtils'
import { useToastContext } from 'src/context/ToastContext'
import { pluralize } from 'src/utils/common'
import useLoader from 'src/hooks/useLoader'
import { CloudDownload, Delete } from '@material-ui/icons'
import { CustomTitle } from './CustomTitle'

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.link.main,
        '&:visited': {
            color: theme.palette.link.active,
        },
        '&:hover': {
            color: theme.palette.link.hover,
            textDecoration: 'underline',
        },
    },
    textEllipsis: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        minWidth: '150px',
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'relative',
        height: '20px',
    },
    criticalBox: {
        backgroundColor: `rgba(${theme.palette.primary.criticalbg})`,
        boxShadow: `0 0 0.5px 2px rgba(${theme.palette.primary.critical})`,
        color: theme.palette.text.darklight,
    },
    highBox: {
        backgroundColor: `rgba(${theme.palette.primary.highbg})`,
        boxShadow: `0 0 0.5px 2px rgba(${theme.palette.primary.high})`,
        color: theme.palette.text.darklight,
    },
    mediumBox: {
        backgroundColor: `rgba(${theme.palette.primary.mediumbg})`,
        boxShadow: `0 0 0.5px 2px rgba(${theme.palette.primary.medium})`,
        color: theme.palette.text.darklight,
    },
    lowBox: {
        backgroundColor: `rgba(${theme.palette.primary.lowbg})`,
        boxShadow: `0 0 0.5px 2px rgba(${theme.palette.primary.low})`,
        color: theme.palette.text.darklight,
    },
    informationalBox: {
        backgroundColor: `rgba(${theme.palette.primary.informationalbg})`,
        boxShadow: `0 0 0.5px 2px rgba(${theme.palette.primary.informational})`,
        color: theme.palette.text.darklight,
    },
    notSetBox: {
        backgroundColor: 'none',
        boxShadow: `0 0 0.5px 2px ${theme.palette.text.resizer}`,
        color: theme.palette.text.darklight,
    },
    customResizer: {
        position: 'absolute',
        right: '-45px',
        top: '-6px',
        height: '40px',
        zIndex: '99999',
    },
    customResizerInner: {
        position: 'absolute',
        right: '-7px',
        top: '5px',
        width: '3px',
        backgroundColor: theme.palette.text.resizer,
        height: '24px',
        cursor: 'col-resize',
        borderRadius: '3px',
        '&:hover': {
            color: `${theme.palette.text.violet}`,
        },
    },
    customTableHeadCell: {
        position: 'relative',
        left: '-10px',
        whiteSpace: 'nowrap',
        borderBottom: 'none',
    },
}))

const SignalsTable = ({
    initialFilters,
    pagination = {
        page: 0,
        rowsPerPage: 10,
    },
    postTableChange = () => {},
}) => {
    const classes = useStyles()
    const [newTableState, setNewTableState] = useState({})
    const [loading, showLoader, hideLoader] = useLoader()
    const [engagements, setEngagements] = useState([])
    const { sendToast, removeAllToast } = useToastContext()
    const [findings, setFindings] = useState({
        count: 0,
        results: [],
    })
    // State to track active status of each button
    const [activeTabs, setActiveTabs] = useState({
        escalated: false,
        outcome: false,
        closed: false,
    })

    // Toggle function to switch each button's active state
    const handleTabClick = (tab) => {
        setActiveTabs((prevTabs) => {
            const newTabs = { ...prevTabs, [tab]: !prevTabs[tab] }

            const statusMap = {
                escalated: 'INVESTIGATING',
                outcome: 'ESCALATED TO CUSTOMER',
                closed: 'CLOSED',
            }

            const tabStatus = statusMap[tab]
            const currentStatus = Array.isArray(filters.status)
                ? filters.status
                : []

            const updatedStatus = newTabs[tab]
                ? [...currentStatus, tabStatus]
                : currentStatus.filter((status) => status !== tabStatus)

            if (
                JSON.stringify(filters.status) !== JSON.stringify(updatedStatus)
            ) {
                setFilters((prevFilters) => {
                    const newFilters = {
                        ...prevFilters,
                        status: updatedStatus,
                    }
                    const statusIndex = newTableState.columns.findIndex(
                        (column) => column.name === 'status'
                    )
                    handleTableChange('filterChange', {
                        ...newTableState,
                        filterList: {
                            ...newTableState.filterList,
                            [statusIndex]: newFilters.status,
                        },
                    })

                    return newFilters
                })
            }

            return newTabs
        })
    }

    const [page, setPage] = useState(pagination.page)
    const [rowsPerPage, setRowsPerPage] = useState(pagination.rowsPerPage)
    const [sortColumn, setSortColumn] = useState({
        direction: 'desc',
        name: 'updated_date',
    })
    const [filters, setFilters] = useState(() => {
        return urlFilter(initialFilters) || {}
    })

    const [selectedRows, setSelectedRows] = useState(new Set())
    const [resizerStyle, setResizerStyle] = useState(0)
    const [mouseEnter, setMouseEnter] = useState(false)
    const [columnDisplay, setColumnDisplay] = useState(false)
    const columns = [
        {
            name: 'title',
            label: 'Title',
            options: {
                colMinWidth: '250',
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const row = findings.results[dataIndex]
                    const parentStyle = {
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        boxSizing: 'border-box',
                        display: 'block',
                        width: '100%',
                    }
                    const cellStyle = {
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }

                    return (
                        <Tooltip
                            arrow
                            title={
                                <span
                                    style={{
                                        fontSize: '13px',
                                        lineHeight: '1.4',
                                        color: '#fff',
                                    }}
                                >
                                    {row.title}
                                </span>
                            }
                            TransitionComponent={Zoom}
                            placement="top-start"
                        >
                            <Link
                                className={`${classes.link} ${classes.textEllipsis}`}
                                href={`/signals/${row.id}`}
                                style={{ position: 'relative', height: '20px' }}
                            >
                                <div style={parentStyle}>
                                    <div style={cellStyle}>{row.title}</div>
                                </div>
                            </Link>
                        </Tooltip>
                    )
                },

                filter: false,
            },
        },
        {
            name: 'id',
            label: 'Short ID',
            options: {
                sort: true,
                colMinWidth: '100',
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = findings.results[dataIndex].id
                    return (
                        <Typography
                            variant="caption"
                            style={{
                                padding: '0 4px',
                                fontWeight: 'bold',
                            }}
                        >
                            {value.substring(0, 8)}
                        </Typography>
                    )
                },
            },
        },
        {
            name: 'type',
            label: 'Signal Type',
            options: {
                sort: true,
                colMinWidth: '140',
                filterList: filters.type,
                filterOptions: {
                    names: [
                        'FINDING',
                        'LEAD',
                        'TTP',
                        'CIS',
                        'INSIGHTS',
                        'MDR',
                        'VULNERABILITY',
                    ],
                    fullWidth: true,
                },
            },
        },
        {
            name: 'incident_severity',
            label: 'Incident Severity',
            options: {
                colMinWidth: '125',
                sort: true,
                display: columnDisplay,
                filterList: filters.incident_severity,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = findings.results[dataIndex].incident_severity
                    return value ? (
                        <Box
                            className={classes[`${value.toLowerCase()}Box`]}
                            style={{
                                maxWidth: '125px',
                                minWidth: '125px',
                                margin: '0',
                            }}
                            textAlign="center"
                            borderRadius={5}
                        >
                            <Typography
                                variant="caption"
                                style={{
                                    padding: '0 4px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {value}
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            className={classes['notSetBox']}
                            style={{
                                maxWidth: '125px',
                                minWidth: '125px',
                                margin: '0',
                            }}
                            textAlign="center"
                            borderRadius={5}
                        >
                            <Typography
                                variant="caption"
                                style={{
                                    padding: '0 4px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Not Set
                            </Typography>
                        </Box>
                    )
                },
                filterOptions: {
                    names: [
                        'CRITICAL',
                        'HIGH',
                        'MEDIUM',
                        'LOW',
                        'INFORMATIONAL',
                    ],
                    fullWidth: true,
                },
            },
        },
        {
            name: 'criticality',
            label: 'Ticket Priority',
            options: {
                colMinWidth: '125',
                sort: true,
                filterList: filters.criticality,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const value = findings.results[dataIndex].criticality
                    return (
                        <Box
                            className={classes[`${value.toLowerCase()}Box`]}
                            style={{
                                maxWidth: '125px',
                                minWidth: '125px',
                                margin: '0',
                            }}
                            textAlign="center"
                            borderRadius={5}
                        >
                            <Typography
                                variant="caption"
                                style={{
                                    padding: '0 4px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {value}
                            </Typography>
                        </Box>
                    )
                },
                filterOptions: {
                    names: [
                        'CRITICAL',
                        'HIGH',
                        'MEDIUM',
                        'LOW',
                        'INFORMATIONAL',
                    ],
                    fullWidth: true,
                },
            },
        },
        {
            name: 'attack_surface',
            label: 'Attack Surface',
            options: {
                colMinWidth: '160',
                sort: true,
                filterList: filters.attack_surface,
                filterOptions: {
                    names: Object.keys(ATTACK_SURFACES),
                    fullWidth: true,
                },
            },
        },
        {
            name: 'created_date',
            label: 'Discovery Date',
            options: {
                colMinWidth: '80',
                sort: true,
                filter: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    let parentStyle = {
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        boxSizing: 'border-box',
                        display: 'block',
                        width: '100%',
                    }
                    let cellStyle = {
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                    return (
                        <div style={{ position: 'relative', height: '20px' }}>
                            <div style={parentStyle}>
                                <div style={cellStyle}>{dataIndex}</div>
                            </div>
                        </div>
                    )
                },
            },
        },
        {
            name: 'updated_date',
            label: 'Last Seen',
            options: {
                colMinWidth: '100',
                sort: true,
                filter: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    let parentStyle = {
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        boxSizing: 'border-box',
                        display: 'block',
                        width: '100%',
                    }
                    let cellStyle = {
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                    return (
                        <div style={{ position: 'relative', height: '20px' }}>
                            <div style={parentStyle}>
                                <div style={cellStyle}>{dataIndex}</div>
                            </div>
                        </div>
                    )
                },
            },
        },
        {
            name: 'project',
            label: 'Project',
            options: {
                colMinWidth: '125',
                sort: false,
                filterList: filters.project,
                filter: false,
                display: 'excluded',
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                colMinWidth: '125',
                sort: true,
                filterList: filters.status,
                filterOptions: {
                    names: ['INVESTIGATING', 'ESCALATED TO CUSTOMER', 'CLOSED'],
                    fullWidth: true,
                },
                display: 'excluded',
            },
        },
    ]
    const fetchData = (params) => {
        showLoader()
        params['page'] += 1
        getAllFindings(params)
            .then((response) => {
                setFindings(response)
            })
            .catch((err) => {
                console.log('getAllFindings', err)
                sendToast(
                    `Error getting findings: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                hideLoader()
            })
    }

    const makeRequestData = (tableState) => {
        const columnFilters = getColumnFilters(tableState)
        const updatedFilters = renameKeys(columnFilters, {
            attack_surface: 'surface',
            investigating: 'escalated',
            ['escalated to customer']: 'outcome',
        })

        if (tableState.searchText) {
            updatedFilters['search'] = tableState.searchText
        }
        updatedFilters['ordering'] = getSortValue(
            tableState.sortOrder.name,
            tableState.sortOrder.direction
        )
        return {
            page: tableState.page,
            limit: tableState.rowsPerPage,
            ...updatedFilters,
        }
    }

    const handleTableInit = (action, tableState) => {
        fetchData(makeRequestData(tableState))
        getAllEngagements()
            .then((response) => {
                setEngagements(response.data)
            })
            .catch((err) => {
                console.log('getAllEngagements', err)
                sendToast(
                    `Error getting engagements: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    const checkAnyFilterSelected = (columns, filters = {}, filterList = []) => {
        filterList = filterList || []

        const severityColumnIndex = columns.findIndex(
            (col) => col.name === 'incident_severity'
        )
        const statusColumnIndex = columns.findIndex(
            (col) => col.name === 'status'
        )
        const isAnyFilterSelected =
            (severityColumnIndex !== -1 &&
                (filters['incident_severity']?.length > 0 ||
                    filterList[severityColumnIndex]?.length > 0)) ||
            (statusColumnIndex !== -1 &&
                (filters['status']?.length > 0 ||
                    filterList[statusColumnIndex]?.length > 0))

        return isAnyFilterSelected
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'filterChange':
            case 'changeRowsPerPage':
            case 'resetFilters':
            case 'search':
            case 'sort':
            case 'changePage': {
                fetchData(makeRequestData(tableState))
                postTableChange(tableState)
            }
            /* falls through */
            // no default
        }
        setNewTableState((prevState) => {
            if (JSON.stringify(prevState) !== JSON.stringify(tableState)) {
                return tableState
            }
            return prevState
        })
    }

    const handleFilterChange = (
        changedColumn,
        filterList,
        type,
        changedColumnIndex,
        displayData
    ) => {
        const isAnyFilterSelected = checkAnyFilterSelected(
            columns,
            filters,
            filterList
        )
        setColumnDisplay(isAnyFilterSelected)

        if (type === 'reset') {
            setFilters({
                criticality: [],
                type: [],
                attack_surface: [],
            })
            return
        }

        const updatedFilters = { ...filters }
        updatedFilters[changedColumn] = filterList[changedColumnIndex]
        setFilters(updatedFilters)
    }

    const handlePageChange = (currentPage) => {
        setPage(currentPage)
    }

    const handleRowsPerPageChange = (numberOfRows) => {
        setRowsPerPage(numberOfRows)
    }

    const handleColumnSortChange = (changedColumn, direction) => {
        setSortColumn({
            name: changedColumn,
            direction: direction,
        })
    }

    const handleRowsDelete = (selectedRows) => {
        showLoader()
        const selectedIds = selectedRows.data.map(
            (row) => findings.results[row.dataIndex].id
        )
        const currentState = {
            page: page,
            rowsPerPage: rowsPerPage,
            sortOrder: sortColumn,
            filterList: columns.map((column) => {
                const filterValues = filters[column.name] || []
                return filterValues.length > 0 ? filterValues : []
            }),
            columns: columns,
        }
        deleteFindings({ ids: Array.from(selectedIds) })
            .then((data) => {
                if (data && data.count !== undefined) {
                    const deletedCount = data.count
                    sendToast(
                        `${deletedCount} ${pluralize(
                            'finding',
                            deletedCount
                        )} deleted`
                    )
                    fetchData(makeRequestData(currentState))
                } else {
                    throw new Error('Invalid response from deleteFindings')
                }
            })
            .catch((err) => {
                console.log('deleteFindings error:', err)
                sendToast(
                    `Error deleting findings: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                setSelectedRows(new Set())
            })
    }

    const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
        if (allRowsSelected.length > 0) {
            let tempSelectedRows = new Set()
            _.forEach(allRowsSelected, (row) => {
                const findingId = findings.results[row.index]?.id
                if (findingId) {
                    tempSelectedRows.add(findingId)
                }
            })
            setSelectedRows(tempSelectedRows)
        } else {
            setSelectedRows(new Set())
        }
    }

    const handleOnDownload = async () => {
        const requestParams = {}
        requestParams['type'] = filters.type
        requestParams['ids'] = Array.from(selectedRows)
        sendToast('Please wait while we process your request.', 'info', false)
        try {
            await exportFindings(requestParams)
            removeAllToast()
            sendToast(
                'Request submitted. Exported Signals will arrive in your email shortly.'
            )
            // Success toast message is already displayed after export operation completes
        } catch (error) {
            removeAllToast()
            sendToast(
                'An error occurred while processing your request. Please try again.'
            )
            return false
        }
    }

    const resizingIndexRef = useRef(null)

    const handleMouseDown = (event, index, columnMeta) => {
        setResizerStyle(parseInt(columnMeta.colMinWidth * 2))
        resizingIndexRef.current = index
        const handleMouseMoveWithColumns = (event) => {
            handleMouseMove(event, index)
        }
        document.addEventListener('mousemove', handleMouseMoveWithColumns)
        document.addEventListener('mouseup', (e) => {
            resizingIndexRef.current = null
            setResizerStyle(0)
            document.removeEventListener(
                'mousemove',
                handleMouseMoveWithColumns
            )
        })
    }

    const handleMouseMove = (event, index) => {
        if (resizingIndexRef.current !== null) {
            const currentColumn = document.querySelector(
                `#customTableHeadCell-${index}`
            )
            if (currentColumn) {
                const mouseX = event.pageX
                const newCurrentColumnWidth =
                    mouseX - currentColumn.getBoundingClientRect().left
                // Apply the new width to the current column only
                currentColumn.style.minWidth = `${newCurrentColumnWidth}px`
            }
        }
    }

    const CustomSelectToolbar = (
        selectedRows,
        displayData,
        setSelectedRows
    ) => {
        return (
            <Box paddingRight={2}>
                <IconButton
                    onClick={() => {
                        handleRowsDelete(selectedRows)
                    }}
                >
                    <Delete fontSize="medium"></Delete>
                </IconButton>
                <IconButton
                    onClick={() => {
                        handleOnDownload()
                    }}
                >
                    <CloudDownload fontSize="medium"></CloudDownload>
                </IconButton>
            </Box>
        )
    }

    const [tableOptions, setTableOptions] = useState({
        responsive: 'standard',
        tableBodyMaxHeight: 'calc(100vh - 290px)',
        filterType: 'multiselect',
        sortFilterList: false,
        jumpToPage: true,
        serverSide: true,
        selectableRows: 'multiple',
        rowsSelected: [],
        count: findings.count,
        textLabels: {
            body: {
                noMatch: 'Loading',
                toolTip: (column) => {
                    return !mouseEnter ? `Sort ${column.label}` : ''
                },
            },
        },
        page: page,
        rowsPerPage: rowsPerPage,
        sortOrder: sortColumn,
        onRowsDelete: handleRowsDelete,
        customSearchRender: debounceSearchRender(500),
        onDownload: handleOnDownload,
        onTableInit: handleTableInit,
        onTableChange: handleTableChange,
        onFilterChange: handleFilterChange,
        onChangePage: handlePageChange,
        onChangeRowsPerPage: handleRowsPerPageChange,
        onColumnSortChange: handleColumnSortChange,
        customToolbarSelect: CustomSelectToolbar,
    })

    useEffect(() => {
        const options = {
            responsive: 'standard',
            tableBodyMaxHeight: 'calc(100vh - 290px)',
            filterType: 'multiselect',
            sortFilterList: false,
            jumpToPage: true,
            serverSide: true,
            selectableRows: 'multiple',
            rowsSelected: [],
            count: findings.count,
            textLabels: {
                body: {
                    noMatch:
                        findings.results.length > 0
                            ? 'Loading'
                            : 'Sorry, no matching records found',
                    toolTip: !mouseEnter ? `Sort` : '',
                },
            },
            page: page,
            rowsPerPage: rowsPerPage,
            sortOrder: sortColumn,
            onRowsDelete: handleRowsDelete,
            onRowSelectionChange: handleRowSelectionChange,
            customSearchRender: debounceSearchRender(500),
            download: false,
            onDownload: handleOnDownload,
            onTableInit: handleTableInit,
            onTableChange: handleTableChange,
            onFilterChange: handleFilterChange,
            onChangePage: handlePageChange,
            onChangeRowsPerPage: handleRowsPerPageChange,
            onColumnSortChange: handleColumnSortChange,
            customToolbarSelect: CustomSelectToolbar,
        }

        setTableOptions(options)
        // eslint-disable-next-line
    }, [findings, selectedRows])

    useEffect(() => {
        setTableOptions((prevOptions) => ({
            ...prevOptions,
            textLabels: {
                body: {
                    toolTip: !mouseEnter ? `Sort ` : '',
                },
            },
        }))
    }, [mouseEnter])

    useEffect(() => {
        const selectedRowsIndexes = Array.from(selectedRows).map((row) => {
            let findingIndex = rowsPerPage + 1
            findings.results.forEach((finding, index) => {
                if (finding.id === row) {
                    findingIndex = index
                }
            })
            return findingIndex
        })

        setTableOptions((prevOptions) => ({
            ...prevOptions,
            rowsSelected: selectedRowsIndexes,
        }))
    }, [selectedRows, rowsPerPage, findings.results])

    useEffect(() => {
        const isAnyFilterSelected = checkAnyFilterSelected(
            columns,
            filters,
            null
        )
        setColumnDisplay(isAnyFilterSelected)
    }, [filters, columns])

    useEffect(() => {
        setActiveTabs(() => ({
            escalated: filters.status?.includes('INVESTIGATING') || false,
            outcome: filters.status?.includes('ESCALATED TO CUSTOMER') || false,
            closed: filters.status?.includes('CLOSED') || false,
        }))
    }, [filters.status])

    return (
        <Box>
            {loading}
            <MUIDataTable
                title={
                    <CustomTitle
                        onTabClick={handleTabClick}
                        activeTabs={activeTabs}
                    />
                }
                data={findings.results}
                columns={columns.map((col, index, colPos) => ({
                    ...col,
                    options: {
                        ...col.options,
                        setCellProps: () => ({
                            className: classes.resizable,
                        }),
                        customHeadLabelRender: (columnMeta) => (
                            <th
                                id={`customTableHeadCell-${index}`}
                                className={`MuiTableCell-root MuiTableCell-head ${classes.customTableHeadCell}`}
                                style={{
                                    minWidth: `${parseInt(
                                        col.options.colMinWidth - 50
                                    )}px`,
                                }}
                            >
                                {columnMeta.label}
                                <Link
                                    style={{
                                        minWidth: `${resizerStyle}px`,
                                    }}
                                    className={classes.customResizer}
                                    onClick={(e) => e.stopPropagation()}
                                    onMouseEnter={() => setMouseEnter(true)}
                                    onMouseLeave={() => setMouseEnter(false)}
                                    ontouchstart={(e) =>
                                        handleMouseDown(
                                            e,
                                            columnMeta.index,
                                            columnMeta
                                        )
                                    }
                                    onMouseDown={(e) =>
                                        handleMouseDown(
                                            e,
                                            columnMeta.index,
                                            columnMeta
                                        )
                                    }
                                    role="button"
                                    tabindex={index}
                                    aria-label="resize column"
                                >
                                    <Tooltip
                                        title={
                                            <span
                                                style={{
                                                    fontSize: '10px',
                                                    lineHeight: '1.4',
                                                }}
                                            >
                                                Resize
                                            </span>
                                        }
                                        TransitionComponent={Zoom}
                                    >
                                        <div
                                            className={
                                                classes.customResizerInner
                                            }
                                        ></div>
                                    </Tooltip>
                                </Link>
                            </th>
                        ),
                    },
                }))}
                options={tableOptions}
            />
        </Box>
    )
}

export default SignalsTable
