import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from 'src/components/Header';
import Account from 'src/views/AdminAccount';
import Assets from 'src/views/Assets';
import Reports from 'src/views/Reports';

//import Quotes from 'src/views/Quotes'
import Signals from 'src/views/Signals';
import IndividualSignal from 'src/views/Signals/IndividualSignals';
// import Outcomes from 'src/views/Outcomes';
// import IndividualOutcome from 'src/views/Outcomes/IndividualOutcomes';
//import AssessmentCreationForm from 'src/views/Assessment'
//import Projects from 'src/views/Projects'
//import IndividualProject from 'src/views/Projects/IndividualProject'
import EngagementDetail from 'src/views/Engagements/EngagementDetail';

import DiscoverDashboard from 'src/views/RTaaS';
// import RTAAS from 'src/views/Dashboards/RTaaS'
import Phishing from 'src/views/Phishing';

// import LogIn from 'src/views/Auth/LogIn'
import PasswordReset from 'src/views/Auth/PasswordReset';
import Verification from 'src/views/Auth/Verification';
import Profile from 'src/views/Profile';
import EULA from 'src/views/Profile/EULA';

import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';


export const renderRoutes = (routes = []) => (
    <Switch>
        {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
                <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                        <Guard>
                            <Layout>
                                {route.routes ? (
                                    renderRoutes(route.routes)
                                ) : (
                                    <Component {...props} />
                                )}
                            </Layout>
                        </Guard>
                    )}
                />
            );
        })}
    </Switch>
);

const routes = [
    // {
    //     exact: true,
    //     guard: GuestGuard,
    //     path: '/login',
    //     component: LogIn,
    // },
    // {
    //     exact: true,
    //     path: '/eula',
    //     component: EULA,
    // },
    // {
    //     exact: true,
    //     guard: GuestGuard,
    //     path: '/reset',
    //     component: PasswordReset,
    // },
    {
        exact: true,
        guard: GuestGuard,
        path: '/validate',
        component: Verification,
    },

    {
        path: '/',
        guard: AuthGuard,
        layout: Header,
        routes: [
            {
                exact: true,
                path: '/',
                component: DiscoverDashboard,
            },
            {
                exact: true,
                path: '/account',
                component: Account,
            },
            {
                exact: true,
                path: '/assets',
                component: Assets,
            },
            // {
            //     exact: true,
            //     path: '/services',
            //     component: Projects,
            // },
            // {
            //     exact: true,
            //     path: '/services/create',
            //     component: AssessmentCreationForm,
            // },
            // {
            //     exact: true,
            //     path: '/services/quotes',
            //     component: Quotes,
            // },
            // {
            //     exact: true,
            //     path: '/services/:projectId',
            //     component: IndividualProject,
            // },
            {
                exact: true,
                path: '/engagements/:engagementId',
                component: EngagementDetail,
            },
            {
                exact: true,
                path: '/signals',
                component: Signals,
            },
            {
                exact: true,
                path: '/signals/:findingId',
                component: IndividualSignal,
            },
            // {
            //     exact: true,
            //     path: '/outcomes',
            //     component: Outcomes,
            // },
            // {
            //     exact: true,
            //     path: '/outcomes/:findingId',
            //     component: IndividualOutcome,
            // },
            // {
            //     exact: true,
            //     path: '/metrics',
            //     component: OSINT,
            // },
            // {
            //     exact: true,
            //     path: '/Detect',
            //     component: MDRDash,
            // },
            // {
            //     exact: true,
            //     path: '/Attack',
            //     component: RTAAS,
            // },
            {
                exact: true,
                path: '/profile',
                component: Profile,
            },
            {
                exact: true,
                path: '/phishing',
                component: Phishing,
            },
            {
                exact: true,
                path: '/reports',
                component: Reports,
            },
        ],
    },
];

export default routes;
