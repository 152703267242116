import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import SignalsTable from './SignalsList'
import { useQuery } from 'src/actions/customHooks'
import { getColumnFilters, renameKeys } from 'src/utils/tableUtils'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
            3
        )}px`,
        position: 'relative',
        top: '-10px',
    },
}))

const FindingsPath = () => {
    const history = useHistory()
    const classes = useStyles()
    let query = useQuery()

    const filters = {
        criticality: query.getAll('severityLevel'),
        incident_severity: query.getAll('incidentSeverity'),
        type: query.getAll('riskType'),
        attack_surface: query.getAll('attackSurface'),
        project: query.getAll('project'),
        engagement: query.getAll('engagement'),
        closed: query.getAll('closed'),
        investigating: query.getAll('escalated'),
        ['escalated to customer']: query.getAll('outcome'),
    }
    const pagination = {
        page: parseInt(query.get('page')) || 0,
        rowsPerPage: parseInt(query.get('limit')) || 10,
    }

    const setUrlQueryParams = (params) => {
        let urlParams = new URLSearchParams()
        for (const [key, value] of Object.entries(params)) {
            if (typeof value === 'object') {
                value.forEach((v) => {
                    urlParams.append(key, v)
                })
            } else {
                urlParams.append(
                    key
                        .replace('investigating', 'escalated')
                        .replace('escalated to customer', 'outcome'),
                    value
                )
            }
        }
        history.replace(`/signals?${urlParams.toString()}`)
    }
    const makeQueryParams = (tableState) => {
        const columnFilters = getColumnFilters(tableState)
        const updatedFilters = renameKeys(columnFilters, {
            attack_surface: 'attackSurface',
            criticality: 'severityLevel',
            incident_severity: 'incidentSeverity',
            type: 'riskType',
            status: 'status',
            investigating: 'escalated',
            ['escalated to customer']: 'outcome',
        })
        return {
            page: tableState.page,
            limit: tableState.rowsPerPage,
            ...updatedFilters,
        }
    }

    return (
        <Box className={classes.root}>
            <SignalsTable
                initialFilters={{ ...filters }}
                pagination={{ ...pagination }}
                postTableChange={(tableState) => {
                    setUrlQueryParams(makeQueryParams(tableState))
                }}
            ></SignalsTable>
        </Box>
    )
}

export default FindingsPath
