import React, { createContext, useReducer, useContext } from 'react'
import { createPortal } from 'react-dom'
import Toast from '../components/Toast'
import AuthContext from './JWTAuthContext'

export const ToastContext = createContext()

const initialState = []

export const ADD = 'ADD'
export const REMOVE = 'REMOVE'
export const REMOVE_ALL = 'REMOVE_ALL'
export const RESET_STATE = 'RESET_STATE'

export const toastReducer = (state, action) => {
    switch (action.type) {
        case ADD:
            return [
                ...state,
                {
                    id: +new Date(),
                    content: action.payload,
                },
            ]
        case REMOVE:
            return state.filter((t) => t.id !== action.payload.id)
        case REMOVE_ALL:
            return initialState
        case RESET_STATE:
            return initialState // Resets the state back to the initial state
        default:
            return state
    }
}

export const ToastProvider = (props) => {
    const { resetAuthStateAndReinitialize } = useContext(AuthContext) // Custom logic from AuthContext
    const [toast, toastDispatch] = useReducer(toastReducer, initialState)

    const sendToast = (
        message,
        severity = 'success',
        autoHideDuration = 5000
    ) => {
        const lowerMessage = message.toLowerCase()

        if (
            lowerMessage.includes('signature has expired') ||
            lowerMessage.includes('not authenticated') ||
            lowerMessage.includes('could not verify token')
        ) {
            resetAuthStateAndReinitialize()
        } else {
            toastDispatch({
                type: ADD,
                payload: { severity, message, autoHideDuration },
            })
        }
    }

    const removeToast = (id) => {
        toastDispatch({ type: REMOVE, payload: { id } })
    }

    const removeAllToast = () => {
        toastDispatch({ type: REMOVE_ALL })
    }

    const toastData = {
        toast,
        toastDispatch,
        sendToast,
        removeToast,
        removeAllToast,
    }

    return (
        <ToastContext.Provider value={toastData}>
            {props.children}
            {createPortal(<Toast toast={toast} />, document.body)}
        </ToastContext.Provider>
    )
}

export const useToastContext = () => {
    return useContext(ToastContext)
}
